import { AddressDelivery } from 'src/redux/addressManagement/types';
import { getCoords } from '../modules/AddressManagement/helpers/getCoords';
import { FetchConfig, Store } from '../redux/apiTypes';
import getStoreById from './getStoreById';
import getStoresByCoords from './getStoresByCoords';
import getStoresByDeliveryType from './getStoresByDeliveryType';
import getStoresByDeliveryTypeAndCoords from './getStoresByDeliveryTypeAndCoords';

const getStoreByDelivery = async (delivery: AddressDelivery, config: FetchConfig): Promise<Store | null> => {
  const deliveryType = delivery?.type;

  if (deliveryType === 'plan' ) {
    const stores = await getStoresByCoords(getCoords(delivery), config);

    return stores?.[0] || null;
  }

  if (deliveryType === 'pickup') {
    if (!delivery.address.pickup) {
      return null;
    }

    const store = await getStoreById(delivery.address.pickup.store_id, config);

    return store || null;
  }

  if ( deliveryType === 'nova_poshta' ) {
    const stores = await getStoresByDeliveryType('nova_poshta', config);

    return stores?.[0] || null;
  }

  if (deliveryType === 'nova_poshta_fresh' ) {
    const stores = await getStoresByDeliveryTypeAndCoords('nova_poshta_fresh', getCoords(delivery), config);

    return stores?.[0] || null;
  }

  return null;
};

export default getStoreByDelivery;
