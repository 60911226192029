import { FetchConfig, Store } from '../redux/apiTypes';

async function getStoreById(storeId: string, config: FetchConfig): Promise<Store> {
  const { apiRoot, xChain, xVersion, language } = config;

  const response = await fetch(
    `${apiRoot}/stores/${storeId}`,
    {
      headers: {
        'Accept-Language': language,
        'x-chain': xChain,
        'x-version': xVersion,
      },
    },
  );

  return await response.json();
}

export default getStoreById;
