import { Coords } from 'src/redux/apiTypes';
import { ServerCartDelivery } from 'src/redux/cart/serverCart/types';

export function getCoords(delivery: ServerCartDelivery): Coords {
  if (delivery.type === 'plan') {
    return delivery.address.plan.coords;
  }

  if (delivery.type === 'pickup') {
    return delivery.address.pickup.store.address.coords;
  }

  if (delivery.type === 'nova_poshta' || delivery.type === 'nova_poshta_fresh') {
    const warehouseCoords = delivery.address[delivery.type].warehouse.coords;

    return {
      lat: Number(warehouseCoords.lat),
      lng: Number(warehouseCoords.lng),
    };
  }
}
